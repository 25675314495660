import React, { useState, useEffect } from 'react'
import {  Button, Menu, Typography, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined, HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined } from '@ant-design/icons';
import icon from './logo.png'

const Navbar = () => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(undefined);
  
    useEffect(() => {
      const handleResize = () => setScreenSize(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      if (screenSize <= 800) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
    }, [screenSize]);

  return (
    <div className="nav-container">
        <div className="logo-container">
        <Avatar src={icon} size="large" />
        <Typography.Title lever={2} className="logo">
        <Button className="menu-control-container" onClick={() => setActiveMenu(!activeMenu)}><MenuOutlined /></Button>
            <Link to= "/">HeadEyes</Link>
            </Typography.Title>
        </div>
        {activeMenu && (
        <Menu theme="dark">
            <Menu.Item icon={<HomeOutlined />}>
                <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item icon={<FundOutlined />}>
                <Link to="/Search">Search</Link>
            </Menu.Item>
            <Menu.Item icon={<MoneyCollectOutlined />}>
                <Link to="/Ammo">Ammo</Link>
            </Menu.Item>
            <Menu.Item icon={<BulbOutlined />}>
                <Link to="/About">About</Link>
            </Menu.Item>
        </Menu>
        )}
    </div>
  )
}

export default Navbar