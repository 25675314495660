import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import './App.css';

function FindGoons() {
  const [formattedData, setFormattedData] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          'https://docs.google.com/spreadsheets/u/0/d/e/2PACX-1vR-wIQI351UH85ILq5KiCLMMrl0uHRmjDinBCt6nXGg5exeuCxQUf8DTLJkwn7Ckr8-HmLyEIoapBE5/pubhtml/sheet?headers=false&gid=1420050773'
        );

        const parsedData = new DOMParser().parseFromString(response.data, 'text/html');
        const secondRow = parsedData.querySelector("tr:nth-child(2)");

        if (secondRow) {
          const timestampCell = secondRow.querySelector("td.s0:nth-child(2)");
          const mapCell = secondRow.querySelector("td.s0:nth-child(3)");

          const timestampValue = timestampCell.textContent.trim();
          const mapValue = mapCell.textContent.trim();

          // Create a JSON object
          const dataObject = {
            map: mapValue,
            timestamp: timestampValue,
          };

          // Convert the object to a JSON string
          const jsonString = JSON.stringify(dataObject);

          // Extract and present the first value (map name) from the JSON string
          const parsedJson = JSON.parse(jsonString);
          const firstValue = parsedJson.map;

          setFormattedData(firstValue);
        } else {
          setFormattedData('Data not found');
        }
      } catch (error) {
        setFormattedData('Error fetching data');
      }
    };

    fetchData();
  }, []);

  return (
    <div className="FindGoons">
      <h2>Currently at:</h2>
      <h1>{formattedData}</h1>
      <h2>Spawn rate: 26% [Updated 1.3]</h2>
    </div>
  );
}

export default FindGoons;
