import "./App.css";

export const ErrorMessage = () => {
  return (
    <div>
      <p className="intro">
        Sorry, we couldn't find that item. 😞
        <br />
        <br />
      </p>
    </div>
  );
};
