import React from 'react'
import { Space, Typography } from 'antd'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
    <Typography.Title level={5} style={{ color: 'white', textAlign: 'center' }}>
      HeadEyes <br />
      All rights reserved
    </Typography.Title>
    <Space>
      <Link to="/">Home</Link>
      <Link to="/Search">Search</Link>
      <Link to="/Ammo">Ammo</Link>
      <Link to="/About">About</Link>
    </Space>
    </div>
  )
}

export default Footer