import { CharacterList } from '../characters';
import Header from '../components/Header'

export default function Search() {
  return (
    <>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1>Item Price Scanner</h1>
      <br></br>
      <CharacterList />
    </>
  )
}
