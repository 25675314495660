import Header from '../components/Header'
import FindGoons from '../findGoons'

export default function Homepage() {
  return (
    <>
     <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
    <Header />
    <FindGoons />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
    <br></br>
    <br></br>
    <h1>OMG I RAN INTO GOONS WHAT I DO??</h1>
  </>
  )
}
