import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import { ErrorMessage } from "./ErrorMessage";
import "./App.css";
import Typography from '@mui/material/Typography';
//import { RowItem } from "./components/RowItem.jsx";

// Fetch Tarkov items
export const GET_ITEMS = gql`
  query Items($userInput: String!, $limit: Int) {
    items(name: $userInput, limit: $limit) {
      id
      name
      shortName
      wikiLink
      image512pxLink
      basePrice
      avg24hPrice
      high24hPrice
      low24hPrice
    }
  }
`;

const RowItem = ({text, price}) => {
  return(
  <Typography>{`${text} ${(price / 1000).toFixed(0)} K ₽`}</Typography>
  )
}

export function CharacterList() {
  const [searchTerm, setSearchTerm] = useState("");

  const { loading, data } = useQuery(GET_ITEMS, {
    variables: { userInput: searchTerm, limit: 3 },
  });

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <input
        type="text"
        name="search"
        placeholder="Search for Tarkov items..."
        value={searchTerm}
        onChange={handleChange}
        className="search-input"
      />
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {data?.items.length === 0 && (
        <div className="no-results">
          <p></p>
          <ErrorMessage />
        </div>
      )}
      {data &&
        data.items.map((item) => (
          <div
            className="card"
            key={item.id}
            style={{
              backgroundImage: `url(${item.image512pxLink})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="info">
              <h2>{item.name}</h2>
              <RowItem text={"📈 Price 24h:"} price={item.high24hPrice}/>
              <RowItem text={"〰️ Price 24h:"} price={item.avg24hPrice}/>
              <RowItem text={"📉 Price 24h:"} price={item.low24hPrice}/>
              <h3>
                <Typography>Wiki Link: <a href={item.wikiLink}>Link</a></Typography>
              </h3>
            </div>
          </div>
        ))}
    </div>
  );
}
